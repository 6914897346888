import type { Ref } from "vue";
import type { SearchParams } from "@/types/search";
import type { MagazinSearchParams } from "~~/types/magazin";

export const defaultTuple = (a: number, b: number): [number, number] => [a, b];

export const durationMin: Ref<number> = ref(0);
export const durationMax: Ref<number> = ref(0);

export const priceMin: Ref<number> = ref(0);
export const priceMax: Ref<number> = ref(0);

export const passengersMin: Ref<number> = ref(0);
export const passengersMax: Ref<number> = ref(0);

export const useSearchFilter = (): SearchParams =>
  reactive({
    filter: {
      all_incl: null,
      cruisetype: "hochsee",
      offertype: null,
      company: "-",
      ship: "-",
      region: "-",
      regions: [],
      departureDate: "",
      arrivalDate: "",
      duration: null,
      price: null,
      harbour: "-",
      stations: "-",
      passengers: null,
      language: "-",
      insider: null,
    },
    offset: 0,
    order: 0,
  });

export const magazinFilter: Ref<MagazinSearchParams> = ref({
  search: "",
  category: null,
});
